.contact-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-image: url('/images/ContactUs.png'); // Use absolute path, omit 'public'
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(99, 66, 231, 1);
  padding: 200px 0 0  0;
  flex-direction: column;
  min-height: 100svh;

  .contact-us-section-content{
    display: flex;
    align-items: center;
    max-width: 90%;
    justify-content: space-between;
    margin-top: 200px;
  }


  .contact-left {
    flex: 1;
    padding-right: 2rem;
    color: rgba(255, 255, 255, 1);
  max-width: 40%;

    h4{
      font-size: calc(1.5625rem + ((1vw - 3.29px) * 2.2502));
      line-height: calc(2rem + ((1vw - 3.29px) * 2.7903));
      font-style: italic;
      font-weight: 100;
      text-align: left;
    }
    h2{
      font-size: calc(1.875rem + ((1vw - 3.29px) * 2.3402));
      line-height: calc(1.875rem + ((1vw - 3.29px) * 2.0702));

      font-weight: 700;
      text-align: left;
      
    }

  }

  .contact-right {
    flex: 1;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 60px;
    padding: 2.5rem;
    max-width: 450px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    height: 571px;
    h3 {
      font-size: 1.8rem;
      font-weight: 600;
      margin-bottom: 1.5rem;
      color: #000;

      
    }

    .contact-form {
      display: flex;
      flex-direction: column;
      height: 85%;
      justify-content: space-between;
      input {
        font-size: 18px;
        font-weight: bold;
        line-height: 24px;
        letter-spacing: -0.5px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        padding: 0.75rem 1rem;
        margin-bottom: 1rem;
        border: none;
        outline: none;
        transition: border-color 0.3s;
        background-color: unset;
        border-bottom: 1px solid rgba(15, 17, 21, 1);

        &:focus {
          border-color: #6b4acc;
        }
      }




      button {
        background: none;
        padding: 0.75rem;
        font-size: 1.1rem;
        font-weight: 600;
        border: none;
        border-radius: 60px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        background: rgba(230, 241, 103, 1);
        margin-top: 50px;
        color: black;
        &:hover {
          background-color: #5a3ab1; // Darker purple on hover
        }
      } 
    }
 
    @media (max-width: 768px) {
      padding: 2rem;
      max-width: 100%;
      border-radius: 30px !important;

   
    }

  }

  @media (max-width: 1400px) {
      padding: 0 0 0 0 !important;
    margin-top: 0;


    .contact-us-section-content{
      align-items: center;
      flex-direction: column;
      max-width: 90%;
        margin-top: 50px !important;
    }
 
    .contact-right{
      max-width: 80%;
      width: 100%;
    }
    .contact-left{
      margin-bottom: 40px;
      max-width: 80%;
      width: 100%;
    }
  }
}