.animation-blok {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 20px 0;
  height: 200px;
}

.carousel {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% * 2);
  gap: 10px;
  animation: scroll 30s linear infinite; /* Fixed animation name */
  transform: translateX(0);
}

.item {
  min-width: 200px;
  padding: 10px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item img {
  max-width: 100%;
  height: auto;
  display: block;
}

.title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

@keyframes scroll {
  0% {
      transform: translateX(0); /* Starting point of the scroll */
  }
  100% {
      transform: translateX(-100%); /* Scroll through all items */
  }
}


@media screen and (max-width:900px){
  .animation-blok{
    height: 15vw;
  }
  .faq-section{
    h2{
      margin-bottom: 6vw;
      text-transform: capitalize;
    }

  }
}