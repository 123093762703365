.member-benefits {
  position: relative;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('/images/img3.png'); // Use absolute path, omit 'public'
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(99, 66, 231, 1);
  perspective: 1000px;
  min-height: 100svh;
  padding-top: 0;

  // Semi-transparent overlay for background
  .benefits-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

 

  .benefits-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    padding: 60px;
    min-height: 780px;
    overflow: hidden;
    box-sizing: border-box;
    max-width: 1256px; 
    width: 100%;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 60px;

    h2 {
      font-weight: 700;
      color: #6b4acc; // Vibrant purple based on the design image
      margin-bottom: 1.5rem;
      text-align: center;
      font-size: 56px;
      font-style: normal;
      font-weight: 700;
      line-height: 150%;
      /* 84px */
      text-transform: uppercase;
      display: inline-block;
      margin-bottom: 70px;
    }

    .benefits-grid {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;

      .benefits-column {
        flex: 1 1 45%; // Two columns, each taking about 45% of the width
        min-width: 250px; // Ensure a reasonable width even on small screens

        ul {
          list-style-type: disc;
          /* Adds bullet points */
          padding: 0;
          box-sizing: border-box;
          /* Include padding in width/height */

          li {
            // font-size: 1.2rem;
            margin-bottom: 1rem;
            color: #333; // Dark text for readability
            font-weight: 500;
            text-align: left;
            color: rgba(15, 17, 21, 1);
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 48px;
            /* 48px */
            list-style: none;
            position: relative;
            padding-left: 28px;
            ;

            &::before {
              content: "";
              position: absolute;
              top: 50%;
              left: 0;
              width: 8px;
              height: 8px;
              transform: translateY(-50%);
              background-color: #000000; // Vibrant purple based on the design image
              border-radius: 50%;
            }
          }

        }
      }

    }
  }
}

@media (max-width: 1400px) {
  .member-benefits {
    .benefits-content {
      padding: 1rem;
      max-width: 90%;
      width: 100%;
      min-height: 720px;
    }
  }

  .benefits-column {
    flex: 1 1 100%; // Stack columns vertically on smaller screens
  }
}


@media screen and (max-width: 900px) {
  .benefits-content {
    border-radius: 30px !important;

    h2 {
      font-size: 30px !important;
      font-style: normal !important;
      font-weight: 700 !important;
      line-height: 150% !important;
      /* 45px */
      text-transform: uppercase;
      margin-bottom: 24px !important;
    }

    .benefits-grid {
      gap: 0 !important;

      .benefits-column {
        ul {
          margin: 0;

          li {
            font-size: 18px !important;
            font-style: normal !important;
            font-weight: 500 !important;
            line-height: 150% !important;
            /* 27px */
            margin-bottom: 10px !important;
            padding-left: 40px !important;
            padding-right: 20px !important;

            &::before {
              left: 20px !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width:   900px) {
  .benefits-content {
    min-height: 600px !important;
    .benefits-grid {
      flex-direction: column;
    }
  }

}