.navbar {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 88;
    min-height: 92px;

    .desktop {
        display: none !important;
    }

    .mobile {
        width: 100px;
        display: block;
    }

    .navbar-container {
        padding: 24px 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .navigation {
            position: fixed;
            right: 30px;
            z-index: 11;

            img {
                opacity: 1 !important;
                border-radius: 10px;
                margin: 0;
                padding: 0;
            }

            .naviation-bottom {
                min-width: 317px;
                position: absolute;
                right: -20px;
                top: -60px;
                background-color: aliceblue;
                border-radius: 30px;
                box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                flex-direction: column;
                padding: 65px 0  30px 0;
                margin-top: 10px;

                a {
                    font-size: 18px;
                    color: rgba(0, 0, 0, 1);
                    display: block;
                    padding: 10px 30px;
                    cursor: pointer;
                    margin-top: 0;
                    width: 100%;
                    text-align: left;
                    box-sizing: border-box;
                    font-size: 25px;
                    font-weight: 500;
                    line-height: 37.5px;

                    &:last-child {
                        background: #e6f267;
                        display: inline-block;
                        width: 80%;
                        border-radius: 90px;
                        color: #000;
                        text-align: center;
                    }
                }
            }
        }

        .social-med {
            position: fixed;
            z-index: 11;
            left: 0;
            top: 82%;
        }
    }
}


@media screen and (max-width: 900px) {
    .navbar {
        .desktop {
            display: none !important;
        }

        .mobile {

            display: block;
        }
    }
}