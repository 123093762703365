.founding-memebers-blok-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('/images/img4.png'); // Use absolute path, omit 'public'
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(99, 66, 231, 1);
  z-index: 4;
  position: relative;
}

.founding-memebers-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 60px;
  padding: 80px 20px;
  max-width: 1256px;
  width: 100%;
  box-sizing: border-box;

  .founding-blok-info {
    text-align: center;
    max-width: 850px;

    h1 {
      color: rgba(230, 241, 104, 1);
      font-size: 56px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      margin-bottom: 28px;
      display: inline-block;
    }
 
    p {
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 42.469px; /* 132.715% */
      color: rgba(255, 255, 255, 1);
      color: rgba(15, 17, 21, 1);
      
    }
    span{
        
         color: rgba(230, 241, 104, 1);

      }

  }

  .founding-blok-icon{
    width: 100%;
    -webkit-mask-image: url(../../images/mask3.svg);
    -webkit-mask-size: contain;
    -webkit-mask-position: center center;
    -webkit-mask-repeat: no-repeat;
    border-radius: 60px;
    overflow: hidden;
    img{
      width: 100%;
      height: 100%;
    }

  }
}
@media screen and (min-width: 900px) and (max-width: 1300px) {
.founding-memebers-content{
  max-width: 90% !important;
}

}

@media screen and (max-width:900px){  
  .founding-memebers-content {
    .founding-blok-icon{
      border-radius:0px;
    }
  }
  .founding-memebers-content{
    max-width: 90%;
    border-radius: 30px;
    padding: 40px 20px;
    .founding-blok-info{
      h1{
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
      }
      p{
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
      }
    }
    .founding-blok-icon{
      max-width: 325px;
    }
  }
}