.video-blok-container {
  .video-blok-iframe-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    h1 {
      color: #fff;
      // font-size: 10vw;
      font-size: 40px;
      margin-bottom: 40px;
      text-transform: uppercase;
      padding-left: 10px;
      font-weight: bold;

      @media screen and (min-width: 450px) and (max-width: 900px) {
      margin-bottom: 82px;
    }
  }
    .elementor-video-iframe {
      position: relative;
      height: 300px;
    }

    span {
      position: absolute;
      text-wrap: nowrap;

    }

    .video-blok-anumation-text-1 {
      transform: translateX(-1500px);
      animation: titleAnimation 0.1s forwards;
      animation-delay: 3.5s; /* Delay starts after the first animation */
    }

    .video-blok-anumation-text-2 {
      transform: translateX(1500px);
      animation: textAnimation2 0.2s forwards;
      animation-delay: 3.6s; /* Delay starts after the first animation */
    }

    .video-blok-anumation-text-3 {
      transform: translateX(-1500px);
        animation: textAnimation3 0.2s forwards;
        animation-delay: 3.8s; /* Delay starts after the first animation */

    }

    @keyframes titleAnimation {
      // 0% {
      //   transform: translateX(200px);
      // }

      100% {
        transform: translateX(-170px);
        // opacity: 0;
      }
    }
    @keyframes textAnimation2 {
      // 0% {
      //   transform: translateX(200px);
      // }

      100% {
        transform: translateX(-170px);
        // opacity: 0;
      }
    }
    @keyframes textAnimation3 {
      // 0% {
      //   transform: translateX(200px);
      // }

      100% {
        transform: translateX(-170px);
        // opacity: 0;
      }
    }
  }

}