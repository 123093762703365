.start-animation-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba(11, 14, 1, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 9999;
  top: 0;
  overflow: hidden;

  .start-animation-logo-content {
    position: relative;
    width: 170px;
    height: 124px;
    display: flex;
    justify-content: space-around;

    .letteral {
      width: 100px;
      height: auto;
      position: absolute;
      transform: translate(-50%, -50%) rotate(90deg); // Initial rotation state
      img{
        width: 160px;
        height: auto;
      }
    }
    .start-animation-g {
        left: 111px;
    }

    .start-animation-a {
        top: 10px;
        left: 0;
    }
  }

  .start-animation-text-content {
    margin-top: 20px;
    overflow: hidden; // Hide the overflowing image until animation starts
    width: 300px; // Ensure the container has the same width as the image
    img {
      width: 100%;
      height: auto;
      transform: scaleX(0); // Initially scale the image to 0 width
      transform-origin: left; // Scale from the left to right
    }
  }
}
