.faq-section {
  display: flex;
  flex-direction: column;
  background-color: #f4f4f9; // Light background color for the section
  text-align: center;
  position: relative;
  background-image: url('/images/img5.png'); // Use absolute path, omit 'public'
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(99, 66, 231, 1);
  align-items: center;
  // This will loop the images for an infinite scroll effect
  min-height: 100svh;
  padding: 100px 0;
  align-items: center;
  h2.title {
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #ffffff;
  }
  h2{
    font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 98%; /* 49px */
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 40px;
  display: inline-block;
  color:#fff;
  }

  .faq-list {
    max-width: 800px;
    margin: 0 auto;

    .faq-item {
      background-color: #ffffff;
      border-radius: 8px;
      margin-bottom: 1rem;
      padding: 1.5rem;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      transition: all 0.3s ease;

      &.open {
        background-color: #e6e6f5;
      }

      .faq-question {
        font-size: 1.2rem;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #333;

        .faq-icon {
          font-size: 1.5rem;
          font-weight: bold;
          transition: transform 0.3s ease;
        }
      }

      .faq-answer {
        margin-top: 1rem;
        font-size: 1rem;
        color: #555;
      }
    }

    @media (max-width: 768px) {
      .faq-item {
        padding: 1rem;
      }

      .faq-question {
        font-size: 4vw !important;
        text-transform: lowercase;
        text-align: left;

        .faq-icon {
          font-size: 1.3rem;
        }
      }

      .faq-answer {
        text-align: left;
        font-size: 3.8vw !important;
      }
    }
  }
}