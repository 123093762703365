.animation-item-container {
    display: flex;
    flex-direction: column; // Stack the image and text vertically
    align-items: center; // Center content horizontally (optional)
    width: 100%; // Ensure the container takes full width
    // margin-left: 230px;
    .backshape{
        background-image: url("../../images/backshape.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: -15px 2%;
    }
    .animation-item-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 700px;
        position: relative; 
        .ab-img{
            position:absolute;
            top:0;
            width: 130px;
            left: 30px; 
            top: 40px;
            z-index: 1;
        }
        .video-image{
            -webkit-mask-image: url("../../images/shape.png");
            -webkit-mask-size: contain;
            -webkit-mask-position: calc(100% - -7px) -50px;
            -webkit-mask-repeat: no-repeat;
            object-position: right;
            height: 1130px;
            width: 100%;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover; // Ensures the video covers the available space
                position: absolute;
                top: 0;
                object-position: calc(80% - 0px);
            }
        }
        img {
            width: 100%; // Image will take up the full width of the container
            height: auto; // Maintain aspect ratio
            object-fit: cover; // Ensures the image covers the available space
        }

        p {
            width: 100%;
            font-size: 30px;
            line-height: 48px;
            // line-height: 150%; /* 45px */
            color: rgba(255, 255, 255, 1);


        }

        span {
            color: rgba(207, 221, 47, 1);



        }



    }

    .story-gif-container {
        // position: relative;
        height: 100vh;

        .story-gif-pic-container {
            position: absolute;
            left: 0;
            z-index: 1;
            clip-path: polygon(0 20%, 100% 0%, 100% 100%, 0% 85%);

            .story-gif-pic {
                left: 0;
            }
        }

        .story-gif-back {
            position: absolute;
            z-index: 0;
        }
    }
}

@media (max-width: 1400px) {
    .animation-item-container {
        margin: 0;
        width: 90%;
    }
}

@media screen and (max-width:900px){
    .animation-item-container {
        .backshape{
            background-image: url("../../images/backshape.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: -5px 2%;
        }
    
        .animation-item-content {
        margin-top: 40px;
        width: 300px;

            .ab-img{
                position:absolute;
                top:0;
                width: 70px;
                left: 20px; 
                top: 8px;
                z-index: 1;
            }
            .video-image{
                -webkit-mask-image: url("../../images/shape.png");
                -webkit-mask-size: contain;
                -webkit-mask-position: calc(100% - -7px) -16px;
                -webkit-mask-repeat: no-repeat;
                object-position: left;
                height: 450px;
                width: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover; // Ensures the video covers the available space
                    position: absolute;
                    top: 0;
                    object-position: calc(80% - 0px);
                }
            }
            p {
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 150%; /* 24px */
                // text-transform: uppercase;
                color: rgba(255, 255, 255, 1);
            }
            span {
                color: rgba(207, 221, 47, 1);
                // text-transform: uppercase;
            }
        }
    
        .story-gif-container {
            // position: relative;
            height: 100vh;
    
            .story-gif-pic-container {
                position: absolute;
                left: 0;
                z-index: 1;
                clip-path: polygon(0 20%, 100% 0%, 100% 100%, 0% 85%);
    
                .story-gif-pic {
                    left: 0;
                }
            }
    
            .story-gif-back {
                position: absolute;
                z-index: 0;
            }
        }
    }
}