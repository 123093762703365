.bio-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}

.bio-modal {
  background: rgba(248, 255, 165, 1);
  padding: 20px;
  border-radius: 60px;
  width: 1150px;
  max-width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  padding: 80px 50px;
}

.bio-content {
  display: flex;
  gap: 20px;
}

.bio-image img {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

.bio-info {
  flex: 1;

  h3 {
    font-size: 32px;
    font-weight: 900;
    line-height: 41.31px;
    text-align: left;

  }
}

h2 {
  font-size: calc(1.125rem + ((1vw - 3.29px) * 1.0801));
  line-height: calc(1.4375rem + ((1vw - 3.29px) * 1.3501));
  // font-size: 30px;
  font-weight: 900;
  // line-height: 38.73px;
  text-align: left;

}

h3 {
  font-size: calc(1.125rem + ((1vw - 3.29px) * 1.2601));
  line-height: calc(1.25rem + ((1vw - 3.29px) * 1.8002));
  ;
}

h4 {
  font-size: calc(0.8125rem + ((1vw - 3.29px) * 1.3501));
  line-height: calc(0.40625rem + ((1vw - 3.29px) * 1.3951));
  // font-size: 28px;
  font-weight: 400;
  // line-height: 22px;
  text-align: left;
}

p {
  font-size: 25px;
  line-height: 35px;

}

.bio-text {
  font-size: 32px;
  font-weight: 500;
  line-height: 38.4px;
  text-align: left;


}

.highlighted-text {
  font-weight: bold;
  color: #5959d1;
}

a {
  margin-top: 5px;
  display: inline-block;
}

.close-button {
  position: absolute;
  top: 35px;
  right: 35px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;

  img {

    width: 58px;
    height: 58px;
  }
}

.close-button:hover {
  color: #5959d1;
}

@media (max-width: 1400px) {
  .bio-modal-overlay {
    .bio-modal {
      height: auto;

      .bio-content {
        align-items: center;
        margin-top: 50px;

        .bio-image {
          width: 90%;
        }

        flex-direction: column;
      }

      .bio-info {
        width: 90%;
      }
    }
  }
}

@media screen and (max-width:900px){
  .close-button  {
    img{
      width: 12vw;
      height: 12vw;
    }
    
}
  .close-button {
    top: 7vw;
    right: 5vw;
  }

  .bio-image{
    img{
      width: 43vw;
    }
  }


  h2 {
    font-size: 3.5vw;
  
  }
  .bio-info  {
    h3{
      font-size: 5vw;
    }
  }
  .bio-content{
    gap:0px;
  }
  .bio-modal-overlay {
    .bio-modal {
      height: 80svh;
      padding: 2vw;
      max-width: 90%;
      border-radius: 30px;
      box-sizing: border-box;
      top: 40px;
      .bio-content {
        align-items: center;
        margin-top: 0;
        .bio-image {
          width: 60%;
        }

        flex-direction: column;
      }

      .bio-info {
        width: 90%;
        .bio-text{
          p{
            font-size: 2.9vw;
            margin-top: 0px;
            line-height: 3.5vw;
          }
        }
      }
    }
  }
}