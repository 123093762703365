  .story-blok-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      // height: 243vh;
      position: relative;
      background-image: url('/images/our-story-bg.png'); // Use absolute path, omit 'public'
      background-size: cover;
      background-repeat: no-repeat;
      background-attachment: fixed; // This keeps the background fixed during scrolling
      // background-color: black;
      background-color: #6342E7;
      z-index: 9;
      .story-blok-container-background {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      .story-blok-info-content {
        width: 50%;

        display: flex;
        flex-direction: column;
        align-items: center;

        .story-blok-info {
          padding: 20px;
          position: sticky;
          transform: translate(0, 0) !important;
          top: 0;
          height: 100vh;
          display: flex;
          justify-content: center;
          flex-direction: column;
          font-size: 2rem;
          z-index: 4;
          left: 0;
          max-width: 420px;
          color: rgba(255, 255, 255, 1);

          h2 {
            text-align: start;
            font-size: 56px;
            font-weight: 700;
            line-height: 71.34px;
          }

          p {
            font-size: 32px;
            font-weight: 500;
            line-height: 48px;
            // line-height: calc(1.4rem + ((1vw - 3.29px) * 1.4941));
            // letter-spacing: -0.05em;
            text-align: start;
          }

          span {
            color: rgba(207, 221, 47, 1);

          }
        }
      }

      .story-blok-galary {
        display: flex;
        flex-direction: column;
        width: 50%;

      }
    }

    @media (max-width: 1400px) {
      .story-blok-container {
        flex-direction: column;
        align-items: center;
        height: auto;

        .story-blok-info {
          position: unset;
          height: auto !important;

          max-width: 90% !important;
          width: 100%;
        }

        .story-blok-galary {
          display: flex;
          gap: 0;
          align-items: center;
          max-width: 90%;
          width: 100%;

        h2 {
          font-size: 30px;
          font-weight: 700;
          line-height: 38.22px;
          
        }

        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 22.4px;
          line-height: 140%; /* 22.4px */

          // letter-spacing: -0.05em;
        }
        }

        span {
          color: rgba(207, 221, 47, 1);

        }
      }
    }


  

@media screen and (max-width:900px){
  .story-blok-container {
    flex-direction: column;
    align-items: center;
    height: auto;
    background-image: url('/images/our-story-bg-small.png'); // Use absolute path, omit 'public'

    .story-blok-info-content{
      width: 100%;
      .story-blok-info {
        position: unset;
        height: auto !important;
  
        max-width: 90% !important;
        width: 300px;
        h2{
          font-size: 30px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        p{
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 150%;
          // letter-spacing: -0.8px;
        }
      }
  
      .story-blok-galary {
        display: flex;
        gap: 0;
        align-items: center;
        max-width: 90%;
        width: 100%;
  
      h2 {
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        
      }
  
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 22.4px;
        // letter-spacing: -0.05em;
      }
      }
  
      span {
        color: rgba(207, 221, 47, 1);
  
      }
    }
  }
    
}