.video-blok-container {
  width: 100%;
  height: 100vh;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1;
  padding: 0;
  overflow: hidden;

  &.sticky {
    position: fixed;
      top: 0; // Adjust this value as needed
      z-index: 0; // Ensure it stays on top of other content
      // Additional styles can go here
  }

  .video-blok-iframe-content {
    height: 100%;
    width: 100%;
    background-color: black;
    overflow: hidden;
    video{
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .play-button{
      position: absolute;
      z-index: 99;
      right: 70px;
      bottom: 70px;
      cursor: pointer;
    }
  }

  .elementor-video-iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    border: none;
    object-fit: cover;
  }

  iframe {
    .player {
      object-fit: contain;
    }
  }

  .video-blok-anumation-content {
    position: absolute;
    z-index: 45;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    text-align: right;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;

    .video-blok-anumation-content-button {
      width: 247px;
      height: 52px;
      border-radius: 74px;
      background: rgba(230, 241, 103, 1);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      font-weight: 500;
      line-height: 30.31px;
      text-align: left;
      animation: buttonAnimation 9s infinite;
      top: -150px;
      right: 15px;
      position: relative;

    }

    .video-blok-anumation-content-text {
      color: rgba(230, 241, 103, 1);
      text-transform: uppercase;
      opacity: 0;
      // font-size: 110px;
      font-size: calc(1.875rem + ((1vw - 3.29px) * 5.0283));
      line-height: calc(1.875rem + ((1vw - 3.29px) * 5.0283));

      font-weight: 700;
        // line-height: 110px;
      animation: none;
      position: absolute;
      // line-height: 140px;
      right: 15px;
    }

    .video-blok-anumation-content-1 {
      bottom: 200px;
      animation: textAnimation 9s infinite;
    }

    .video-blok-anumation-content-2 {
      animation: textAnimation2 9s infinite;
      animation-delay: 3s;
      bottom: 70px;
    }

    .video-blok-anumation-content-3 {
      animation: textAnimation2 9s infinite;
      animation-delay: 6s;
      bottom: 70px;
    }

    @keyframes textAnimation {
      0% {
        opacity: 0;
        transform: translateX(-100px);
      }

      10% {
        opacity: 1;
        transform: translateX(0);
      }

      30% {
        opacity: 1;
      }

      40% {
        opacity: 0;
        transform: translateX(0);
      }

      100% {
        opacity: 0;
      }
    }

    @keyframes textAnimation2 {
      0% {
        opacity: 0;
        transform: translateX(100px);
      }

      10% {
        opacity: 1;
        transform: translateX(0);
      }

      30% {
        opacity: 1;
      }

      40% {
        opacity: 0;
        transform: translateX(0);
      }

      100% {
        opacity: 0;
      }
    }

    @keyframes buttonAnimation {
      0% {
        top: -150px
      }

      30% {
        top: -150px
      }

      40% {
        top: -30px;
      }

      90% {
        top: -30px;
      }

      100% {
        top: -150px
      }
    }

    // @keyframes moveRight-3 {
    //   0% {
    //     transform: translate(0);
    //   }
    //   100% {
    //     transform: translate(100%);
    //   }
    // }
  }
}
.hide-desktop{
  display: none;
}
@media screen and (max-width:900px){
  .video-blok-container .video-blok-iframe-content{
    background-color: none !important;
    background-image: url("../../images/44.jpg");
    background-size: cover;
    background-position: center center;
  }
  .hide-desktop{
    display: block;
  }
}