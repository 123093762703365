.social-media-container{
    width: 35px;
    height: 125px;  
    background: rgba(255, 255, 255, 0.5);
    border-radius: 0 20px 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    position: absolute;
    z-index: 40;
    img{
        width: 20px;
        height: 20px;
    }
}