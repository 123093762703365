.founder-blok-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('/images/img3.png'); // Use absolute path, omit 'public'
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(99, 66, 231, 1);
  z-index:1;
  position: sticky;
  min-height: 100svh;
  padding: 0;
  .founder-blok-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    background: rgba(255, 255, 255, 0.5);
    max-width: 1252px;
    width: 100%;
    border-radius: 60px;
    padding: 58px 60px;
    gap: 30px;
    box-sizing: border-box;
    .founder-blok-picture {
      position: relative;

      .founder-add-icon {
        position: absolute;

        bottom: 30px;
        right: 30px;

        max-width: min-content;
        cursor: pointer;

        img {
          width: calc(2.6875rem + ((1vw - 3.29px) * 5.4005));
          height: calc(2.6875rem + ((1vw - 3.29px) * 5.4005));
        }
      }
    }

  }

  .founder-blok-info {
    h1 {
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
      margin-bottom: 10px;
    }

    h2 {
      font-size: 28px;
      font-style: normal;
      font-weight: 300;
      line-height: 22px; /* 78.571% */
      margin-bottom: 42px;
      display: inline-block;
    }

    p {
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 35.2px */
      max-width: 519px;
      display: inline-block;
    }

  }

  .founder-blok-icon {
    position: absolute;
    right: 60px;
    top: 60px;
  }


}


@media (max-width: 1400px) {
  .founder-blok-container {

    .founder-blok-content {
      flex-direction: column;
      padding: 60px 0 20px 0;

      .founder-add-icon {

        bottom: 10px !important;
        right: 77px !important;
      }

      .founder-blok-picture {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .founder-blok-picture-back {
          width: 80%;
          height: auto;
        }
      }

      .founder-blok-info {
        width: 80%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;

        
      }
    }

  }

}

@media screen and (min-width: 900px) and (max-width: 1300px) {
  .founder-blok-content{
    max-width: 90% !important;
  }
}
@media screen and (max-width:900px){
  .founder-blok-container{
    min-height: 75svh;
  }


  .founder-blok-container {
      
    .founder-blok-content {
      flex-direction: column;
      padding: 60px 0 20px 0;
      max-width: 90%;
      border-radius: 30px;
      .founder-add-icon {

        bottom: 15px !important;
        right: 90px !important;
      }

      .founder-blok-picture {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .founder-blok-picture-back {
          width: 60%;
          height: auto;
        }
      }

      .founder-blok-info {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80%;
        h1{
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;
        }
        h2{
          font-size: 13px;
          font-style: normal;
          font-weight: 300;
          line-height: 110%; /* 17.6px */
          margin-bottom: 0;
        }
        p{
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 110%; /* 17.6px */
        }
        
      }
      .founder-blok-icon{
        width: 60px;
        right: 20px;
        top:20px;
        img{
          max-width: 100%;
        }
      }
    }

  }

}