.footer-section {
    // background-color: #6b4acc; // Main purple background color
    padding: 100px 0 0 0;
    color: #fff;
    // text-align: center;
    width: 100%;
    // padding: 100px 0 0 0 !important ;
    // @media (max-width: 768px) {
    //   padding: 2rem;
    // }
  
    .footer-top {
      background: rgba(122, 91, 253, 1);

      // background-color: rgba(255, 255, 255, 0.1); // Light purple box for Connect With Us
      padding: 2rem;
      border-radius: 15px;
      margin-bottom: 2rem;
  
      h3 {
        text-align: center;
        font-size: 1.8rem;
        font-weight: 600;
        margin-bottom: 1rem;
      }
  
      .footer-icons {
        display: flex;
        justify-content: center;
        gap: 1.5rem;
  
        a {
          color: #fff;
          font-size: 1.8rem;
          transition: color 0.3s;
  
          &:hover {
            color: #ffdd57; // Hover effect, yellowish color for icons
          }
        }
      }
    }
  
    .footer-menu {
      display: flex;
      justify-content: center;
      gap: 3rem;          
      margin-bottom: 2rem;
  
      a {
        color: #fff;
        font-size: 1.1rem;
        font-weight: 500;
        transition: color 0.3s;
        cursor: pointer;
        &:hover {
          color: #ffdd57; // Hover effect for the menu links
        }
      }
   
  
      @media (max-width: 768px) {
        // flex-direction: column;
        gap: 1rem;
        flex-wrap: wrap;
      }
    }
  
    .footer-line{
      border: 1px solid rgba(122, 94, 237, 1)
    }
    .footer-bottom {
      font-size: 1rem;
      opacity: 0.7;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      gap:20px;
      margin-bottom: 20px;
      // padding-bottom: 30px;
      p{
        font-size: 18px;
        font-weight: 400;
        line-height: 28.8px;
        padding-bottom: 0;
        margin-bottom: 0; 
      }
      img{
        width: 50px;
        margin-top: 10px;
      }
      a{
        color:#fff;
        text-decoration: underline;
      }
    }
  }
  