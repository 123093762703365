body {
  padding: 0;
  margin: 0;
}

section {
  padding: 200px 0;
}

.global-content {
  //margin-top: 100vh;
  position: relative;
  z-index: 2;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  text-decoration: none !important;
}

// /* Import Restora Font */
// @font-face {
//   font-family: 'Restora';
//   src: url('./fonts/AkzidenzGroteskPro-Bold.eot') format('truetype');
//   font-weight: normal;
//   font-style: normal;
// }

/* Import Akzidenz-Grotesk Pro Font */
// @font-face {
//   font-family: 'AkzidenzGroteskPro';
//   src: url('./fonts/AkzidenzGroteskPro-Bold.woff') format('woff');
//   font-weight: bold;
//   font-style: normal;
// }

@font-face {
  font-family: "Akzidenz";
  src:
    url("./font/AkzidenzGroteskPro-Regular.ttf") format("ttf");
}

@font-face {
  font-family: "Akzidenz-bold";
  src:
    url("./font/AkzidenzGroteskPro-Bold.ttf") format("ttf");
}

@font-face {
  font-family: "Restora";
  src:
    url("./font/RestoraExtraLight.otf") format("otf");
}

@font-face {
  font-family: "Restora-italic";
  src:
    url("./font/RestoraThinItalic.otf") format("otf");
}


/* Apply the fonts to your project */
body {
  font-family: 'Akzidenz', sans-serif;
  /* Example: Apply Restora globally */
}
.overlay-gradient-top{
  position: absolute;
    top: 0;
    width: 100%;
    height: 40svh;
    z-index: -3;
    background: linear-gradient(180deg, #6342e7 20.5%, rgb(99 66 231 / 0%) 100%);
}
.overlay-gradient-bottom{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 40svh;
  z-index: -3;
  background: linear-gradient(0deg, #6342e7 1.5%, rgb(99 66 231 / 0%) 100%);
}

@media (max-width: 1400px) {
  .block-kontent {
    max-width: 90%;
    width: 100%;
  }
  section {
    padding: 50px 0;
  }
}

.asd{
  background-color: red;
  height: 1440px;
  position: fixed;
  width: 100vw;
}


.contact-form input::placeholder{
  color:#000;
}