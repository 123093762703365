.films-blok-container {
  background-image: url('/images/img1.png'); // Use absolute path, omit 'public'
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba(99, 66, 231, 1);
  z-index: 0;
  min-height: 100svh;
  box-sizing: border-box;
  padding: 0;
  display: flex;
  align-items: center;
  margin-top: 100svh;

  &.sticky {
    position: fixed;
    top: 0;
  }

  .films-blok-content {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: space-between;
    border-radius: 60px;
    background: rgba(255, 255, 255, 0.50);
    padding: 1.8vw 2vw;
    max-width: 1252px;
    position: relative;
    gap: 40px;
    box-sizing: border-box;
    margin: 0 auto;
  }

  .films-blok-picture {
    min-height: 567px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 60px;
    }

    .films-blok-icon {
      position: absolute;
      max-width: max-content;
      left: -20px;
      top: -20px;
    }
  }

  .films-blok-info {
    max-width: 50%;
    flex-basis: 50%;
    color: rgba(15, 17, 21, 1);

    svg {
      margin-bottom: 10px;
    }

    h1 {
      color: #0F1115;
      font-family: "Restora-italic";
      font-size: 50px;
      font-style: italic;
      font-weight: lighter;
      line-height: normal;
      text-transform: uppercase;
      font-weight: bold;


    }

    h2 {
      font-size: 56px;
      line-height: 54.88px;
      text-align: left;
      // margin-bottom: 40px;
      display: inline-block;
      font-weight: bold;

    }


    p {
      font-size: 24px;
      line-height: 140%;
      /* 33.6px */
      text-align: left;
      font-weight: 500;
    }

  }


}

@media (max-width: 1400px) {
  .films-blok-container {

    .films-blok-content {
      flex-direction: column-reverse;

      .films-blok-info {
        width: 80%;

        h1 {
          font-size: 25px;
          line-height: 31.25px;
          text-transform: uppercase;
          font-weight: bold;

        }

        h2 {
          font-size: 30px;
          line-height: 30.88px;
          font-weight: bold;

        }

        p {
          font-size: 16px;
          line-height: 22.8px;
          font-weight: 500;

        }
      }
    }

    .films-blok-icon {
      left: auto;
      right: -15px;
      top: -15px;

      img {
        width: 88px;
        height: 88px;
      }
    }
  }

}

@media screen and (min-width: 900px) and (max-width: 1300px) {
  .films-blok-content {
    max-width: 90% !important;
  }
}

@media screen and (max-width:900px) {
  .films-blok-container {

    .films-blok-content {
      flex-direction: column-reverse;
      flex-wrap: wrap;
      align-items: center;
      height: auto;
      gap: 10px;
      max-width: 90%;
      padding: 24px 20px;
      border-radius: 30px;
      margin: 20px auto;

      .films-blok-info {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;


        svg {
          // width: 50%;
          margin-bottom: 25px;
        }

        h1 {
          font-size: 2svh;
          line-height: 31.25px;
          text-transform: uppercase;
          font-weight: bold;
        }

        h2 {
          font-size: 30px;
          font-style: normal;
          line-height: 30px;
          /* 29.4px */
          text-transform: uppercase;
          margin-bottom: 2vw;
          font-weight: bold;
        }

        p {
          font-size: 18px;
          line-height: 27px;
          margin-top: 0;
          font-weight: 500;
        }
      }
    }

    .films-blok-picture {
      min-height: auto;
      position: relative;
      height: 70vw;
      width: 100%;

      & img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 40px;
        ;
      }

      .films-blok-icon {
        left: auto;
        right: -15px;
        top: -15px;

        img {
          position: static;
          width: 88px;
          height: 88px;
        }
      }
    }
  }

}